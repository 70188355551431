const pathname = {
    home: '/',
    registration: '/registration',
    anunciantes: '/anunciantes',
    constraction: '/constraction',
    negocio: '/negocio',
    success: '/success',
    notFound: '*',
}
 
export default pathname;